@use 'src/styles/typography';

.listItem {
  margin: 4px 0;
  padding-left: 32px;
  display: inline-block;
  cursor: pointer;

  &Text {
    @extend .body-m;
    margin: 0;
  }
}
