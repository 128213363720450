:root[data-theme='light'] {
  --compact-card-background-color: var(--primary-2);
  --compact-card-border-color: #ffffff;
}

:root[data-theme='dark'] {
  --compact-card-background-color: var(--primary-3);
  --compact-card-border-color: var(--primary-2);
}

.card {
  background-color: var(--compact-card-background-color);
  border-left: 1px solid var(--compact-card-border-color);
  border-right: 1px solid var(--compact-card-border-color);

  &Comparing:nth-child(n) {
    background-color: var(--comparing-background-3);
  }

  &ComparingFocused:nth-child(n) {
    background-color: var(--comparing-background-2);
  }

  &HeaderComparing:nth-child(n) {
    color: var(--white-color);
  }
}

.dataItem {
  position: absolute;
  z-index: 6;
}
