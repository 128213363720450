.wrapper {
  display: flex;
  align-items: center;

  .buttonsContainer {
    display: flex;
    margin-left: 24px;
    margin: 0 -8px;

    .button {
      cursor: pointer;
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--contrast);
      margin: 0 8px;
    }
  }
}
